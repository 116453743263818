import React, { useMemo } from "react";
import { GetServerSidePropsContext } from "next";

import { getPageData } from "@/lib/contentul/api";

import PreviewBanner from "@/components/preview-banner/preview-banner";

import {
  TResPageData,
  TResSectionModel,
} from "@JohnLewisPartnership/jlfs-ui-library/dist/types/restResolvedTypes";

import { renderer } from "../utils/bootstrap";

export async function getServerSideProps({
  req,
  params,
  preview = false,
}: GetServerSidePropsContext) {
  try {
    const pageData = await getPageData(params?.slug as string[], preview);
    const hasPageData = !!pageData.length;

    // return 404 if no page data found
    if (!hasPageData) {
      return { notFound: true };
    }

    const pageSlug = pageData[0].fields.pageMetadata.fields.slug;

    // There should only ever be one page entry per
    return {
      props: {
        pageData: pageData[0],
        slug: pageSlug,
        preview: preview,
      },
    };
  } catch (err) {
    if (err instanceof Error) {
      console.error(
        err.message,
        `: Failed to get data for slug: ${params?.slug}`
      );
    }

    // return 404 on unknown errors too ?
    return { notFound: true };
  }
}

export default function Page(params: {
  pageData: TResPageData;
  slug: string;
  preview: boolean;
  host: string;
}) {
  const { pageData, slug, preview } = params;

  const pageSections = pageData.fields.sections as TResSectionModel[];

  const render = useMemo(() => {
    const view = renderer(pageSections, preview);
    return view;
  }, [pageSections]);

  return (
    <>
      {/* {renderer(pageSections)} */}
      {render}
      {preview && <PreviewBanner slug={slug} />}
    </>
  );
}
