import styles from './preview-banner.module.scss';

type TPreviewBannerProps = {
    slug: string
}
export default function PreviewBanner({slug}: TPreviewBannerProps) {
    return (
        <div className={styles.PreviewBanner}>
            <span>You are viewing the website in Preview Mode</span>
            <a role="button" href={"/api/exit-preview?slug=" + slug}>Exit Preview Mode</a>
        </div>
    );
}